
import { defineComponent } from 'vue';
import BusinessDetailsForm from '@/views/new-design/CompleteProfile/BusinessDetailsForm.vue';
import AddressForm from '@/components/forms/AddressForm.vue';
import AddressLookup from '@/components/AddressLookup.vue';

export default defineComponent({
  name: 'business-details',
  props: {
    values: {
      type: Object,
      default: () => undefined,
    },
    errors: {
      default: () => {
        return [] as any
      }
    }
  },
  data() {
    return {
      isManual: false,
    };
  },
  emits: [
    'businessCountryChange',
    'businessCountryBlur',
    'businessStateChange',
    'businessStateBlur',
    'emailChange',
    'nameChange',
    'onSearchAddress',
  ],
  components: {
    BusinessDetails: BusinessDetailsForm,
    BusinessAddressForm: AddressForm,
    AddressLookup,
  },
  methods: {
    handleCountryChange(value) {
      this.$emit('businessCountryChange', value);
    },
    handleCountryBlur() {
      this.$emit('businessCountryBlur');
    },
    handleStateChange(value) {
      this.$emit('businessStateChange', value);
    },
    handleStateBlur() {
      this.$emit('businessStateBlur');
    },
    handleEmailChange(value) {
      this.$emit('emailChange', value);
    },
    handleNameChange(value) {
      this.$emit('nameChange', value);
    },
    setPlace(place) {
      this.$emit('onSearchAddress', place);
    },
  },
});
