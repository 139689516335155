
import { defineComponent } from 'vue';
import { StepperComponent } from '@/assets/ts/components';
import { Form } from 'vee-validate';
import StepperNav from '@/views/new-design/CompleteProfile/Stepper.vue';
import OwnerDetails from '@/views/new-design/CompleteProfile/OwnerDetails.vue';
import BusinessDetails from '@/views/new-design/CompleteProfile/BusinessDetails.vue';

import {
  Owner,
  Business,
} from '@/views/new-design/validations/CompleteProfile';

import {
  Getters as AuthGetters,
  Actions as AuthActions,
} from '@/store/enums/AuthEnums';
import {
  Actions as PrincipalActions,
  Getters as PrincipalGetters,
} from '@/store/enums/PrincipalEnums';
import {
  Actions as BusinessActions,
  Getters as BusinessGetters,
} from '@/store/enums/BusinessEnums';
import { Actions as StoreActions } from '@/store/enums/StoreEnums';
import { mapActions, mapGetters } from 'vuex';
import { Principal } from '@/models/PrincipalModel';
import store from '@/store';
import toasts from '@/utils/toasts';
import moment from 'moment';
import router from '@/router';
import _ from 'lodash';

const initialValues = {
  state: '',
  country: 'AU',
  business_state: '',
  business_country: 'AU',
  business_address_line: '',
} as unknown as Principal;

export default defineComponent({
  name: 'complete-account-profile-wizard',
  props: {
    colClass: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    currentStep: 0,
    form: {} as typeof Form,
    formData: initialValues,
    loading: false,
    stepper: {} as StepperComponent,
    formErrors: [] as any
  }),
  components: {
    Form,
    StepperNav,
    Step1: OwnerDetails,
    Step2: BusinessDetails,
    //Step3: ProfileCompleted,
  },
  mounted() {
    // setup the form reference
    this.form = this.$refs.completeProfileForm as typeof Form;

    // setup the stepper
    this.stepper = StepperComponent.createInsance(
      this.$refs.completeProfileStepperForm as HTMLElement
    ) as StepperComponent;

    // show page loading
    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);
  },
  computed: {
    ...mapGetters({
      user: AuthGetters.GET_AUTH_USER,
      actionErrors: PrincipalGetters.GET_PRINCIPAL_ACTION_ERROR,
      principal: PrincipalGetters.GET_PRINCIPAL,
    }),
    formStepSchema() {
      return this.formSchema[this.currentStep];
    },
    formSchema() {
      return [Owner, Business];
    },
    initValues() {
      return {
        ...this.formData,
        ...{
          email: this.user.email,
          country: this.user.country ? this.user.country : 'AU',
          business_country: this.user.business_country
            ? this.user.business_country
            : 'AU',
        },
      };
    },
    totalSteps(): number {
      return 2;
    },
    addressErrors(){
      let errors = [] as any;
      const keys = ['business_address_line', 'business_city', 'business_post_code', 'business_state'];

      _.forEach(this.formErrors, (value, key) => {
        if(keys.includes(key)){
          errors.push({ $message: value });
        }
      });

      return errors;
    }
  },
  methods: {
    ...mapActions({
      completeProfile: AuthActions.SUBMIT_COMPLETE_PROFILE,
      fetchAuthUser: AuthActions.FETCH_AUTH_USER,
      searchAgency: BusinessActions.SEARCH_AGENCY,
    }),
    handleOwnerStateChange(value) {
      this.setFieldValue('state', value);
    },
    handleOwnerCountryChange(value) {
      this.setFieldValue('country', value);
    },
    handleOwnerCountryBlur() {
      this.validateFormField('country');
    },
    handleOwnerStateBlur() {
      this.validateFormField('state');
    },
    handleBusinessCountryChange(value) {
      this.setFieldValue('business_country', value);
    },
    handleBusinessCountryBlur() {
      this.validateFormField('business_country');
    },
    handleBusinessStateChange(value) {
      this.setFieldValue('business_state', value);
    },
    handleBusinessStateBlur() {
      this.validateFormField('business_state');
    },
    skipCompleteProfile() {
      router.push({ name: 'dashboard' });
    },
    nextStep(values) {
      this.formData = {
        ...this.formData,
        ...values,
        ...{
          business_country: values.business_country ?? 'AU',
        },
      };

      this.currentStep++;

      if (!this.stepper) {
        return;
      }

      this.form.resetForm();
      this.stepper.goNext();
    },
    previousStep() {
      if (!this.stepper) return;

      this.currentStep--;
      this.stepper.goPrev();
    },
    resetForm() {
      // Reset the form validation and values
      this.form.resetForm();

      // Reset the form stepper
      this.currentStep = 0;
      this.stepper.goFirst();

      // Reset the form values
      this.form.setValues(this.principal ? this.principal : initialValues);
      this.formData = this.principal ? this.principal : initialValues;
    },
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    async submitCompleteProfileForm(values) {
      if (this.loading) return;
      this.loading = true;

      this.formData = {
        ...this.formData,
        ...values,
        ...{
          date_of_birth: moment(values.date_of_birth).format('YYYY-MM-DD'),
        },
      };

      /**
       * Delete pre-filled ID from review
       *
       * Note: Do not remove
       */
      delete this.formData.id;
      // End: Delete pre-filled ID from review

      const { valid, errors } = await (
        this.$refs.completeProfileForm as any
      ).validate();

      if (!valid) {
        this.loading = false;
        this.formErrors = errors;
        console.log(errors);
        return false;
      }

      await this.handleEmailChange(values.business_email_address);
      await this.handleNameChange(values.business_name);

      await this.completeProfile(this.formData)
        .then(async () => {
          this.loading = false;
          this.resetForm();
          toasts.success('Account profile completed!');
          await this.fetchAuthUser();
          router.push({ name: 'dashboard' });
        })
        .catch(() => {
          this.loading = false;
          const { errors, messages } = this.actionErrors;
          toasts.error(errors, messages);
        });
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
    async handleEmailChange(email) {
      if (email && email != '') {
        await this.searchAgency({ email: email }).then((res: any) => {
          if (res) {
            (this.$refs as any).completeProfileForm.setErrors({
              business_email_address:
                'An account with that business email is already registered with Tenant Hub',
            });
            return false;
          }
        });
      }
    },
    async handleNameChange(name) {
      if (name && name != '') {
        await this.searchAgency({ name: name }).then((res: any) => {
          if (res) {
            (this.$refs as any).completeProfileForm.setErrors({
              business_name:
                'An account with that business name is already registered with Tenant Hub',
            });
            return false;
          }
        });
      }
    },
    setPlace(place) {
      this.setFieldValue('business_state', place.state);
      this.setFieldValue('business_city', place.city);
      this.setFieldValue('business_post_code', place.post_code);
      this.setFieldValue('business_state', place.state);
      this.setFieldValue('business_country', place.country);
      this.setFieldValue('business_address_line', place.address);
    },
  },
  watch: {
    user(value) {
      if (!value) return;

      this.formData = {
        ...this.formData,
        ...value,
        ...{
          email: value.email ?? value.email,
          country: value.country ?? 'AU',
          business_country: value.business_country ?? 'AU',
        },
      };
    },
    currentStep(){
      this.formErrors = []
    }
  },
});
