
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    isManual: {
      default: false,
    },
    label: {
      default: 'Search your address:',
    },
    placeholder: {
      default: 'Search a place here',
    },
    country: {
      default: () => {
        return ['au'];
      },
    },
    types: {
      default: () => {
        return ['address'];
      },
    },
    labelClass: {
      default: 'fs-6 fw-bolder form-label text-dark',
    },
    errors: {
      default: () => {
        return [] as any
      }
    }
  },
  data() {
    return {
      places: null as any,
    };
  },
  emits: ['onSelect', 'onManual'],
  computed: {
    manual: {
      get() {
        return this.isManual;
      },
      set(value) {
        this.$emit('onManual', value);
      },
    },
  },
  methods: {
    getAddressValue(type, length = 'long_name') {
      const address = this.places?.address_components.find((item) => {
        return item.types.includes(type);
      });

      return address ? address[length] : '';
    },
    setPlace(place) {
      this.places = place;

      const data = {
        state: this.getAddressValue(
          'administrative_area_level_1',
          'short_name'
        ),
        address: `${this.getAddressValue(
          'street_number'
        )} ${this.getAddressValue('route')}`.trim(),
        city: this.getAddressValue('locality'),
        post_code: this.getAddressValue('postal_code'),
        country: this.getAddressValue('country', 'short_name'),
      };

      this.$emit('onSelect', data);
    },
    manualInput() {
      this.manual = true;
    },
  },
});
