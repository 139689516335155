import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormField, {
      required: true,
      name: "business_name",
      containerClass: "col-lg-12",
      label: "Business Name",
      placeholder: "Business Name",
      type: "text",
      onBlur: _ctx.onChangedName
    }, null, 8, ["onBlur"]),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_email_address",
      containerClass: "col-lg-6 col-md-6",
      label: "Business Email Address",
      placeholder: "Business Email Address",
      type: "email",
      onBlur: _ctx.onChangedEmail
    }, null, 8, ["onBlur"]),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_website",
      containerClass: "col-lg-6 col-md-6",
      label: "Website",
      placeholder: "example.com",
      type: "text"
    }),
    _createVNode(_component_FormField, {
      required: true,
      name: "abn",
      containerClass: "col-lg-6 col-md-6",
      label: "ABN",
      placeholder: "ABN",
      type: "text",
      mask: _ctx.abnMasking,
      onKeypress: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isNumber($event)))
    }, null, 8, ["mask"]),
    _createVNode(_component_FormField, {
      required: true,
      name: "acn",
      containerClass: "col-lg-6 col-md-6",
      label: "ACN",
      placeholder: "ACN",
      type: "text",
      mask: _ctx.acnMasking,
      onKeypress: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isNumber($event)))
    }, null, 8, ["mask"]),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_phone_number",
      containerClass: "col-lg-6 col-md-6",
      label: "Office Number",
      placeholder: "Office Number",
      type: "text",
      mask: _ctx.phoneMasking,
      onKeypress: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isNumber($event)))
    }, null, 8, ["mask"])
  ]))
}