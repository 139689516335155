import regexUtil from '@/utils/regex';
import maskUtil from '@/utils/mask';
import {
  createValidationObjectSchema,
  formatMatches,
} from '@/utils/validations';

export const Owner = createValidationObjectSchema({
  first_name: {
    required: true,
  },
  last_name: {
    required: true,
  },
  date_of_birth: {
    required: true,
  },
  mobile_number: {
    errorTextFieldLabel: 'Mobile number',
    required: true,
    match: formatMatches.mobile.aus,
  },
});

export const Address = createValidationObjectSchema({
  address_line: {
    errorTextFieldLabel: 'Address',
    required: true,
  },
  city: { required: true },
  state: { required: true },
  post_code: {
    match: formatMatches.postalCode.aus,
    required: true,
  },
  country: { required: true },
});

export const Business = createValidationObjectSchema({
  business_name: {
    required: true,
  },
  business_email_address: {
    errorTextFieldLabel: 'Email address',
    format: 'email',
    required: true,
  },
  business_website: {
    errorTextFieldLabel: 'Website URL',
    match: [regexUtil.website, 'Please provide a valid website URL'],
    required: true,
  },
  // business_mobile_number: {
  //   errorTextFieldLabel: 'Mobile number',
  //   required: true,
  //   match: formatMatches.mobile.aus,
  // },
  business_phone_number: {
    errorTextFieldLabel: 'Phone number',
    required: true,
    match: formatMatches.phone.aus,
  },
  abn: {
    errorTextFieldLabel: 'ABN',
    required: true,
    match: [regexUtil.abn, maskUtil.business.abn, true],
  },
  acn: {
    errorTextFieldLabel: 'ACN',
    match: [regexUtil.acn, maskUtil.business.acn, true],
  },
  business_address_line: {
    errorTextFieldLabel: 'Address',
    required: true,
  },
  business_city: {
    errorTextFieldLabel: 'Suburb',
    required: true,
  },
  business_state: { errorTextFieldLabel: 'State', required: true },
  business_post_code: {
    errorTextFieldLabel: 'Post Code',
    match: formatMatches.postalCode.aus,
    required: true,
  },
  business_country: { errorTextFieldLabel: 'Country', required: true },
});
